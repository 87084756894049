.wrapper {
  && {
    margin: 0;
    & span {
      left: -8px;
      top: 4px;
      margin-left: 8px;
      margin-top: -6px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: 2px solid var(--color-tarmac-grey-500);
      border-radius: 50%;
      display: inline-block;
      position: relative;

      &:hover {
        border-color: var(--color-tarmac-grey-900);
        &::after {
          background-color: var(--color-neutral-700);
        }
      }

      /* for inner filled circle */
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--color-neutral-700);
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }

    .error {
      border-color: var(--color-red-700);
    }

    & input {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 2px solid var(--color-tarmac-grey-500);

      &:checked + span {
        border: 2px solid var(--color-neutral-700);
      }

      &:checked + span:after {
        opacity: 1;
      }

      &:disabled + span {
        color: var(--color-tarmac-grey-300);
        &::before,
        &::after {
          border-color: var(--color-tarmac-grey-500);
        }
      }
    }

    & label {
      font-size: 14px;
      font-weight: 400;
      margin-right: 7px;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      margin-bottom: 12px;
      line-height: 22px;
      &:disabled + label {
        cursor: default;
      }
    }

    &:disabled + label {
      cursor: default;
      pointer-events: none;
    }

    & small {
      display: block;
      margin: 4px 0;
      color: var(--color-red-700);
    }
  }
  .icon {
    padding-left: var(--spacing-4);

    @media (--mobile) {
      margin-top: var(--spacing-1);
      display: block;
      width: 100%;
    }
  }
}

.noBorder {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  margin: var(--spacing-8) 0;
}

.hasBorder {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: var(--spacing-4) 0;
}

.hasBorder label {
  display: inline-flex;
  font-size: 16px;
  align-items: center;
  padding: var(--spacing-4);
  border: 1px solid var(--color-neutral-100);
  width: 100%;

  @media (--mobile) {
    display: block;
    font-size: 14px !important;
  }
  @media (--tablet) {
    display: inline-flex;
  }

  @media (--tablet-l) {
    display: block;
  }

  @media (--desktop-l) {
    display: inline-flex;
  }

  & svg {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    width: 54px;
    margin: 0 -6px;
  }
}

.hasBorder label:nth-child(2) {
  margin-top: -12px;
}
.hasBorder label.selected {
  border-color: var(--color-neutral-700);
}
