.wrapper {
  & h6,
  & h5 {
    margin-top: 0;
  }
}

.usp {
  padding: 0;
  background: var(--color-mica-blue-50);
  display: flex;
  overflow: hidden;
  contain: content;
  flex-direction: column-reverse;

  @media (--tablet-l) {
    flex-direction: row;
  }

  &.alignLeft {
    @media (--tablet-l) {
      flex-flow: row-reverse;
    }
  }

  [data-tenant='rac'] & {
    background: none;
    gap: var(--spacing-3);

    @media (--tablet-l) {
      gap: var(--spacing-10);
    }

    @media (--desktop-l) {
      gap: var(--spacing-20);
    }
  }
}

.promos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: var(--spacing-6) var(--spacing-3);

  @media (--tablet-l) {
    flex: 1 1 50%;
    padding: var(--spacing-5) var(--spacing-10) var(--spacing-5) var(--spacing-5);
  }

  @media (--desktop-l) {
    padding: var(--spacing-10) var(--spacing-20) var(--spacing-10) var(--spacing-10);
  }

  &.promosBorder {
    border: 1px solid var(--color-tarmac-grey-200);
    border-top: 0;

    @media (--tablet-l) {
      border-right: 0;
      border-top: 1px solid var(--color-tarmac-grey-200);
    }
  }

  [data-tenant='rac'] & {
    padding: 0;
  }
}

.uspsHeader {
  display: block;

  @media (--mobile) {
    margin-bottom: var(--spacing-5);
    font-size: 24px;
  }

  @media (--tablet) {
    margin-bottom: var(--spacing-6);
  }

  @media (--tablet-l) {
    margin-bottom: var(--spacing-8);
  }

  @media (--desktop-l) {
    margin-bottom: var(--spacing-10);
  }
}

.uspTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-3);
  gap: var(--spacing-3);

  & h6 {
    &h6 {
      margin: 0;
    }
  }

  [data-tenant='rac'] & {
    color: var(--color-primary-500);
  }
}

.uspIcon {
  position: relative;
  aspect-ratio: 1/1;
  height: 20px;

  [data-tenant='rac'] & {
    fill: var(--color-primary-500);
  }
}

.uspHeader {
  font-weight: bold;
}

.ad {
  flex: 1 1 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: var(--spacing-5) 0;

  @media (--tablet-l) {
    flex: 1 1 50%;
    margin-bottom: 0;
  }

  [data-tenant='rac'] & {
    padding: 0;
  }
}

.adPromoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 auto;
  z-index: 1;
  padding-left: var(--spacing-5);

  & h2 {
    color: #fff;
    margin-bottom: var(--spacing-5);
    display: inline;
    text-align: left;
    line-height: 1.7;
    font-size: 24px;
    font-size: max(24px, 5vw);
    letter-spacing: -1.5px;

    @media (--mobile) {
      font-size: 24px;
    }

    @media (--tablet) {
      line-height: 1.6;
      font-size: 50px;
      font-size: min(50px, 5vw);
      letter-spacing: -2.5px;
    }
    @media (--tablet-l) {
      padding-left: 0;
    }

    & span {
      box-decoration-break: clone;
      background: var(--color-primary-700);
      padding: 0 10px;
    }
  }
}

.with {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-primary-700);
  padding: 0 10px;

  display: flex;
  gap: 12px;

  @media (--tablet) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 0px;
  }

  & svg {
    width: auto;
    height: clamp(48px, 9vw, 55px);
    padding: 10px 0;

    @media (--tablet) {
      height: 55px;
      padding: 10px 0;
    }

    & :global(.logo-text) {
      fill: var(--color-primary-700);
    }
  }

  [data-tenant='rac'] & {
    display: none;
  }
}

.adImage {
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;

  & img {
    position: static !important;
    object-fit: cover;
    object-position: center;
  }
}

.promo {
  padding: var(--spacing-5) 0;

  @media (--tablet-l) {
    &:first-child {
      padding-top: 0;
    }
  }

  &:last-child {
    border-bottom: none !important;
    padding-bottom: 0;
  }

  > p {
    margin: 0;
  }

  & h5 {
    margin-bottom: var(--spacing-5);
  }
  &.promoBorder {
    border-bottom: 1px solid var(--color-mica-blue-100);
  }
}

.link {
  margin-top: var(--spacing-4);
  color: var(--color-neutral-700);
}
