.fullWidthHeaderWrapper {
  border-bottom: 1px solid var(--color-primary-50);
}

.headerWrapper {
  border-bottom: 1px solid var(--color-primary-200);
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.08);
  &[data-is-internal='true'] {
    display: none;
  }
  @media (--desktop) {
    &[data-is-internal='true'] {
      display: inherit;
    }
  }
  @media (--desktop) {
    border-bottom: none;
    box-shadow: none;
  }
  h3,
  h6 {
    margin: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  padding: var(--spacing-5);
  @media (--desktop) {
    flex-direction: row;
    padding: var(--spacing-6) var(--spacing-16);
    align-items: center;
  }
}

.headerWrapper,
.mainWrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.mainWrapper {
  background: var(--color-primary-50);
  @media (--desktop) {
    display: flex;
    flex-direction: row;
  }
}

.content {
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-10);
  position: relative;
  background: var(--color-primary-50);

  width: 100%;

  @media (--desktop-s) {
    max-width: 1080px;
  }

  @media (--desktop-l) {
    max-width: 1153px;
  }
  &:before {
    content: '';
    position: absolute;
    background: var(--color-primary-50);
    top: 0;
    bottom: 0;
    width: 100vh;
    left: 100%;
  }

  @media (--desktop) {
    padding: var(--spacing-10) var(--spacing-10) var(--spacing-20);
  }
}
