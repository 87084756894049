.sideNav {
  display: none;
  background: var(--color-championship-white);
  padding: var(--spacing-10) var(--spacing-10) var(--spacing-10) var(--spacing-16);
  min-height: 630px;
  flex: 1 1 287px;
  max-width: 287px;
  @media (--desktop) {
    display: flex;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-5);
    li {
      list-style-type: none;
      padding: 0;
      margin: 0;

      a,
      button {
        text-decoration: none;
        display: flex;
        color: var(--color-neutral-700);
        gap: var(--spacing-5);
        white-space: nowrap;
        text-wrap: wrap;
      }
      &:last-child {
        margin-top: auto;
        border-top: 1px solid var(--color-primary-100);
      }
    }
  }
}

.logoutButton {
  > span {
    font-size: 14px;
    font-weight: 500;
  }
}
