.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--color-primary-50);
  > button {
    text-decoration: none;
    color: inherit;
  }
  @media (--desktop) {
    display: none;
  }
}
.drawer {
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  background: #00000080;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.isOpen {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    & .drawerContent {
      transform: translateY(0);
    }
  }
}

.drawerContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: var(--spacing-10) var(--spacing-5);
  background-color: var(--color-championship-white);
  transform: translateY(110vw);
  transition: transform 0.3s ease 0.1s;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-5);
    li {
      list-style-type: none;
      padding: 0;
      margin: 0;

      a,
      button {
        text-decoration: none;
        display: flex;
        color: var(--color-neutral-700);
        gap: var(--spacing-5);
      }
      button {
        min-height: auto;
      }

      &:last-child {
        margin-top: auto;
        border-top: 1px solid var(--color-primary-100);
        padding: var(--spacing-8) 0 0;
      }
    }
  }
}
