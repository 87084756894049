.wrapper {
  display: grid;
  gap: var(--spacing-5);
  padding: var(--spacing-4) 0 0;

  & h5 {
    margin: 0;
  }
}
.header {
  display: flex;
  align-items: center;
  gap: var(--spacing-5);
}

.grid {
  display: grid;
  grid-gap: var(--spacing-5);
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  @media (--tablet-l) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.bottom {
  @media (--tablet) {
    display: flex;
    gap: var(--spacing-2);
  }
}
